<template>
    <div style="width:100%; height:90px; position: fixed; top:0px; z-index:999; background-color: #ffffff;">
        <div class="shadow" style="width:100%; height:86px; display:flex; justify-content: center;">
            <div style="width:80%; display:flex; justify-content:space-between;">
                <div style="width:360px; ">
                    <router-link to="/" @click="menu('0')">
                        <div style="float:left; width: 50px;height: 80px;"><img src="../assets/logo.png" style="width:80px;height:80px;"/></div>
                        <div style="float: left;padding-top: 15px;margin-left: 50px;font-size: 36px;color: #000000;font-weight: bold;"><span>墨钉科技</span></div>
                    </router-link>
                </div>
                <div style="display:flex; justify-content: right;">
                    <router-link to="/about" @click="menu('1')"><div :class="acitveValue=='1'?'active':''" class="menu dropdown">关于墨钉</div></router-link>
                    <div :class="acitveValue=='2'?'active':''" class="menu dropdown" @click="menu('2-1','0')" style="position:relative;">产品中心
                        <div class="dropdown-content">
                            <div class="dropdown-menu">
                                <router-link to="/product" @click.stop="menu('2-1')"><div class="dropdown-menu-child">> 电子纸</div></router-link>
                                <router-link to="/board" @click.stop="menu('2-2')"><div class="dropdown-menu-child">> 电路板</div></router-link>
                            </div>
                        </div>
                    </div>
                    <div :class="acitveValue=='3'?'active':''" class="menu dropdown" @click="menu('3-1','0')" style="position:relative;">解决方案
                        <div class="dropdown-content">
                            <div class="dropdown-menu">
                                <router-link to="/solution/one" @click.stop="menu('3-1')"><div class="dropdown-menu-child">> 公交站牌</div></router-link>
                                <router-link to="/solution/two" @click.stop="menu('3-2')"><div class="dropdown-menu-child">> 智能拉环</div></router-link>
                                <router-link to="/solution/three" @click.stop="menu('3-3')"><div class="dropdown-menu-child">> 墨水屏桌牌</div></router-link>
                            </div>
                        </div>
                    </div>
                    <router-link to="/case" @click="menu('4')"><div :class="acitveValue=='4'?'active':''" class="menu dropdown">案例展示</div></router-link>                    
                    <router-link to="/contact" @click="menu('6')"><div :class="acitveValue=='6'?'active':''" class="menu dropdown">联系我们</div></router-link>
                    <router-link to="/support/guide" @click="menu('5')"><div :class="acitveValue=='5'?'active':''" class="menu dropdown">服务支持</div></router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {ref} from 'vue'
    import {useRouter} from 'vue-router'
    export default {
        setup(){
            let acitveValue=ref('0');
            let activeChild=ref('')
            let route=useRouter()
            let menu=function(idx){
                console.log('menu',idx);
                acitveValue.value=idx.substring(0,1);
                if(idx.length>1){
                    activeChild.value=idx;
                }
                if(arguments.length>1){
                    if(idx=='2-1'){
                        route.push('/product')
                    }else if(idx=='3-1'){
                        route.push('/solution/one')
                    }
                    
                }
            };
            return {
                menu,acitveValue,activeChild
            };
        }
    }
</script>
<style scoped>
a{text-decoration:none}
.menu{
    margin-top: 26px;font-size: 20px;color: #4a4a4a;margin-right: 40px;
}
.shadow{
    -moz-box-shadow: 10px 10px 40px #888888; /* 老的 Firefox */
    box-shadow: 0px 0px 5px #888888;
}

.dropdown-content {
    display: none;    
    position: absolute;left: 0px;top: 20px;z-index: 3;
}
.dropdown-menu{
    margin-top: 39px;
    border-top: 2px solid #06338e;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);    
}
.dropdown-menu-child{
    font-size: 16px;
    padding: 6px 20px;
    min-width: 100px;
    background-color: #ffffff;
    color: #3d3d3d;
    font-weight: 400;

}
.dropdown-menu-child:hover{
    background-color: #06338d;
    color: #ffffff;
}

.dropdown-content a:hover {background-color: #06338e}
.dropdown{
    cursor:pointer;
}
.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover {
    color: #06338e; font-weight: bold;
}
.active{
    color: #06338e; font-weight: bold;
}

</style>