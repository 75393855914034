<template>
    <Head></Head>
    <div style="margin-top:90px;">
        <slot></slot>
    </div>
    <Footer v-if="fullFooter==0"></Footer>
</template>
<script>
    import Head from "./HeadComponent.vue"
    import Footer from "./FooterComponent.vue"
    import { useRouter } from "vue-router"    
    import {onMounted,ref,watch} from 'vue'
    export default {  
        components: {
            Head,Footer
        },      
        setup(){
            const router=useRouter();
            let path=ref('')
            let fullFooter=ref(0)
            onMounted(()=>{
                //页面渲染完执行
                 path.value=router.currentRoute.value.fullPath;
                 console.log('layout.....',path.value)
            });
            watch(()=>router.currentRoute.value.path, (newVal,oldVal) => {
                console.log('newVal',newVal)
                if(newVal.indexOf('/baseconfig/')>1 && !newVal.endsWith('/1')){
                    document.documentElement.scrollTop = document.body.scrollTop =400
                }else{
                    document.documentElement.scrollTop = document.body.scrollTop =0
                }
                if(newVal.indexOf('/support/')>-1){
                    fullFooter.value=1
                }else{
                    fullFooter.value=0
                }
            }, { immediate: true })
            return {
                path,
                fullFooter
            };
        }
    }

</script>