<template>
   <div style="width:100%; height:260px; background-color: #000000; color:#ffffff;  display: flex; justify-content: center;">
      <div style="width:80%; display:flex; justify-content:space-between;">
         <div style="width:70%; display: flex; flex-direction: column;">
            <div style="width:100%; display:flex; margin-bottom:10px;">
               <router-link to="/"><div class="menu">首页 </div></router-link>
               <div class="split"></div>
               <router-link to="/about"><div class="menu">关于墨钉</div></router-link>
               <div class="split"></div>
               <router-link to="/product"><div class="menu">产品中心</div></router-link>
               <div class="split"></div>
               <router-link to="/solution"><div class="menu">解决方案</div></router-link>
               <div class="split"></div>
               <router-link to="/case"><div class="menu">案例展示</div></router-link>
               <div class="split"></div>
               <router-link to="/contact"><div class="menu">联系我们</div></router-link>
               <div class="split"></div>
               <router-link to="/support"><div class="menu">服务支持</div></router-link>
            </div>
            <div style="width:100%; display: flex;">
               <div class="menu mainDiv">&nbsp;</div>
               <div class="menu menuDiv">&nbsp;</div>
               <router-link to="/product"><div class="menu menuDiv">电子纸</div></router-link>
               <router-link to="/solution"><div class="menu menuDiv">公交站牌</div></router-link>
            </div>
            <div style="width:100%; display: flex;">
               <div class="menu mainDiv">&nbsp;</div>
               <div class="menu menuDiv">&nbsp;</div>
               <router-link to="/board"><div class="menu menuDiv">电路板</div></router-link>
               <router-link to="/solution/two"><div class="menu menuDiv">智能拉环</div></router-link>
            </div>
            <div style="width:100%; display: flex;">
               <div class="menu mainDiv">&nbsp;</div>
               <div class="menu menuDiv">&nbsp;</div>
               <div class="menu menuDiv">&nbsp;</div>
               <router-link to="/solution/three"><div class="menu menuDiv" style="width:80px;">墨水屏桌牌</div></router-link>
            </div>
            <div style="width: 100%;display: flex;color: #999999;margin-top: 40px;font-size: 14px;">
               <span>墨钉信息科技（上海）有限公司版权所有</span> <span style="margin-left: 40px;">沪ICP备17030171号-1</span>
            </div>
         </div>
         <div style="width: 30%;display: flex;flex-direction: column;justify-content: center; font-size: 14px; font-weight: 400; color: #999999;">
            <div>地址：上海市闵行区田林路科技绿洲三期1036号16号楼</div>
            <div style="margin-top:20px;">邮箱：sales@modingtech.com</div>
         </div>
         
      </div>
   </div>
</template>
<style scoped>
   a{text-decoration:none}
   .menu{
       margin-top: 32px; font-size: 16px; color: #a0a0a0;
   }
   .mainDiv{
      width:32px; margin-top:2px;
   }
   .menuDiv{
      width:64px; margin-top:10px; margin-left:49px; font-size: 14px; font-weight: 400;
   }
   .split{
      border-left:1px solid #3d3d3d; margin-left:23px; margin-right:23px; width:2px; height:16px; margin-top:36px;
   }
   </style>