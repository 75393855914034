<template>
  <div style="height:600px; width:100%; position: relative;">
    <swiper
    :spaceBetween="30"
    :centeredSlides="true"
    :autoplay="{
      delay: 4000,
      disableOnInteraction: false,
    }"
    :pagination="pagination"
    :navigation="true"
    :modules="modules"
    @slideChange="onSlideChange"
    class="mySwiper"
  >
    <swiper-slide><img src="../assets/001.png"/></swiper-slide>
    <swiper-slide><img src="../assets/002.png"/></swiper-slide>
    <swiper-slide><img src="../assets/003.png"/></swiper-slide>
    <swiper-slide><img src="../assets/004.png"/></swiper-slide>
  </swiper>
</div>
<div ref="div1" id="div1" style="width: 350px;height: 240px;position: absolute;top: 320px;z-index: 9;left: 60%;">
  <div style="color: #b82222;font-size: 30px;font-weight: bold;">电子墨水屏公交站牌</div>
  <div style="color: #3b3d3d; font-size: 35px; font-weight: bold; padding: 10px;">彩色屏</div>
  <div style="border-top:1px solid #3d3d3d; height:4px; width:280px;">&nbsp;</div>
  <div style="color: #3b3d3d; padding: 5px;font-size: 16px;">·类纸显示效果，室内户外皆可使用 </div>
  <div style="color: #3b3d3d; padding: 5px;font-size: 16px;">·超低功耗，节能环保</div>
  <div style="color: #3b3d3d; padding: 5px;font-size: 16px;">·云端实时监控 </div>
  <div style="color: #3b3d3d; padding: 5px;font-size: 16px;">·支持多种色彩</div>
</div>
<div  ref="div2" id="div2" style="width: 350px;height: 240px;position: absolute;top: 320px;z-index: 9;left: 60%;">
  <div style="color: #06338d;font-size: 30px;font-weight: bold;">电子墨水屏公交站牌</div>
  <div style="color: #3b3d3d; font-size: 35px; font-weight: bold; padding: 10px;">黑白屏</div>
  <div style="border-top:1px solid #3d3d3d; height:4px; width:280px;">&nbsp;</div>
  <div style="color: #3b3d3d; padding: 5px;font-size: 16px;">·类纸显示效果，室内户外皆可使用 </div>
  <div style="color: #3b3d3d; padding: 5px;font-size: 16px;">·超低功耗，节能环保</div>
  <div style="color: #3b3d3d; padding: 5px;font-size: 16px;">·云端实时监控 </div>
</div>
<div  ref="div3" id="div3" style="width: 350px;height: 240px;position: absolute;top: 320px;z-index: 9;left: 70%;">  
  <div style="color: #3b3d3d; font-size: 35px; font-weight: bold; padding: 10px;">墨水屏桌牌</div>
  <div style="border-top:1px solid #3d3d3d; height:4px; width:190px;">&nbsp;</div>
  <div style="color: #3b3d3d; padding: 5px;font-size: 16px;">·智能投屏，操作简单 </div>
  <div style="color: #3b3d3d; padding: 5px;font-size: 16px;">·支持多种颜色规格</div>
</div>
<div  ref="div4" id="div4" style="width: 350px;height: 240px;position: absolute;top: 320px;z-index: 9;left: 60%;">
  <div style="color: #3b3d3d; font-size: 35px; font-weight: bold; padding: 10px;">智能拉环</div>
  <div style="border-top:1px solid #3d3d3d; height:4px; width:240px;">&nbsp;</div>
  <div style="color: #3b3d3d; padding: 5px;font-size: 16px;">·即传即刷，批量投屏，简单快捷 </div>
  <div style="color: #3b3d3d; padding: 5px;font-size: 16px;">·支持定时自动刷新 & 轮播显示</div>
</div>
<div style="display:flex;flex-direction:column;">
  <div style="text-align: center;padding: 50px 0px 20px 0px;font-size: 40px;font-weight: bold; color:#3d3d3d;">
    支持和维护
  </div>
  <div style="display: flex; justify-content: center;">
    <div style="border-top:2px solid #06338d; height:5px;width:120px;"> &nbsp;</div>
  </div>
  <div style="display:flex; justify-content:center; margin-top:30px;">
    <div style="display:flex; justify-content:space-between; width:1180px;">
      <router-link to="/support/api/addr">
      <div style="width:350px; height:320px; background-color:#f4f6f9;">
        <div style="display:flex; flex-direction:column; padding-top:40px;">
          <div style="display:flex; justify-content:center; ">
            <img src="../assets/005.jpg"/>
          </div>
          <div style="display:flex;justify-content:center;font-size: 24px;padding: 20px;font-weight: bold;color: #3d3d3d; ">
            接口对接
          </div>
          <div style="display:flex;justify-content:center;font-size: 18px;padding: 6px;color: #3d3d3d; ">
            支持接口对接服务
          </div>
          <div style="display:flex;justify-content:center;font-size: 18px;padding: 6px;color: #3d3d3d; ">
            实现自主管理和维护
          </div>
        </div>
      </div>
    </router-link>
    <router-link to="/support/guide">
      <div style="width:350px; height:320px; background-color:#f4f6f9;">
        <div style="display:flex; flex-direction:column; padding-top:40px;">
          <div style="display:flex; justify-content:center; ">
            <img src="../assets/006.jpg"/>
          </div>
          <div style="display:flex;justify-content:center;font-size: 24px;padding: 20px;font-weight: bold;color: #3d3d3d; ">
            产品培训
          </div>
          <div style="display:flex;justify-content:center;font-size: 18px;padding: 6px;color: #3d3d3d; ">
            提供日常维护和使用方法
          </div>
          <div style="display:flex;justify-content:center;font-size: 18px;padding: 6px;color: #3d3d3d; ">
            系统异常维修等流程的培训
          </div>
        </div>
      </div>
    </router-link>
    <router-link to="/support/guide">
      <div style="width:350px; height:320px; background-color:#f4f6f9;">
        <div style="display:flex; flex-direction:column; padding-top:40px;">
          <div style="display:flex; justify-content:center; ">
            <img src="../assets/007.jpg"/>
          </div>
          <div style="display:flex;justify-content:center;font-size: 24px;padding: 20px;font-weight: bold;color: #3d3d3d; ">
            技术文档
          </div>
          <div style="display:flex;justify-content:center;font-size: 18px;padding: 6px;color: #3d3d3d; ">
            提供产品使用文档，接口对接
          </div>
          <div style="display:flex;justify-content:center;font-size: 18px;padding: 6px;color: #3d3d3d; ">
            系统故障排除等辅助文档
          </div>
        </div>
      </div>
    </router-link>
    </div>
  </div>
  <div style="text-align: center;padding: 50px 0px 20px 0px;font-size: 40px;font-weight: bold; color:#3d3d3d;">
    案例展示
  </div>
  <div style="display: flex; justify-content: center;">
    <div style="border-top:2px solid #06338d; height:5px;width:120px;"> &nbsp;</div>
  </div>
</div>
<div style="display: flex; justify-content: center; margin-top:30px; margin-bottom: 50px;">
  <div style="height:400px; width:80%; position: relative;">
    <swiper
    :slidesPerView="'auto'"
    :spaceBetween="20"
    :freeMode="true"
    :autoplay="{
      delay: 3000,
      disableOnInteraction: false,
    }"
    :pagination="{
      clickable: true,
    }"
    :modules="modules"
    class="mySwiper"
    >
      <swiper-slide><img src="../assets/008.png" style="width:420px; height:400px;"/></swiper-slide>
      <swiper-slide><img src="../assets/009.png" style="width:360px; height:400px;"/></swiper-slide>
      <swiper-slide><img src="../assets/010.png" style="width:440px; height:400px;"/></swiper-slide>
      <swiper-slide><img src="../assets/011.png" style="width:370px; height:400px;"/></swiper-slide>
      <swiper-slide><img src="../assets/012.png" style="width:460px; height:400px;"/></swiper-slide>
      <swiper-slide><img src="../assets/013.png" style="width:200px; height:400px;"/></swiper-slide>
    </swiper>
  </div>
</div>

</template>
<script>
import {onMounted,ref} from 'vue'
import {velo} from '../js/3316564104f'
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    let arrDiv=[0,0,0,0];
    let swiperIndex=ref(0);
    onMounted(()=>{
      //页面渲染完执行
      swiperIndex.value=0;
      velocity(0);
    });
    function velocity(idx){
      document.getElementById("div1").style.display="none";
      document.getElementById("div2").style.display="none";
      document.getElementById("div3").style.display="none";
      document.getElementById("div4").style.display="none";
      document.getElementById("div"+(parseInt(idx)+1)).style.display="block";
        arrDiv[idx]=1;
        idx=parseInt(idx)+1;
        let id="div"+idx;
        velo(id,0.9,1.5);
    }
    const onSlideChange=(swiper)=>{
      swiperIndex.value=swiper.activeIndex;
      velocity(swiper.activeIndex);
    };
    const pagination={
      clickable: true,
      // type: 'progressbar',
      renderBullet: function (index, className) {
        console.log('className',className);
        console.log('index',index);
        return '<span class="' + className + '" style="width:20px;height:20px; margin-left:30px; color:#fff;">' + "</span>";
      },
    };
    return {
      onSlideChange,
      pagination,
      modules: [Autoplay, Pagination, Navigation],
      swiperIndex,
    };
  },
};

</script>
<style scoped>
a{text-decoration:none}
#div1{
    width: 35%;
  overflow: hidden;
  margin: 0 auto;
}
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height:20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
  color: #ffffff;
  background: #ffffff;
  background-color: #ffffff;
}
.swiper-slide {
  width: 0px;
}

.swiper-slide:nth-child(1n) {
  width: 420px;
}
.swiper-slide:nth-child(2n) {
  width: 360px;
}

.swiper-slide:nth-child(3n) {
  width: 440px;
}
.swiper-slide:nth-child(4n) {
  width: 370px;
}
.swiper-slide:nth-child(5n) {
  width: 460px;
}
.swiper-slide:nth-child(6n) {
  width: 200px;
}
</style>