<template>
<Layout>
    <router-view/>
</Layout>
</template>

<script>
import Layout from "./components/LayoutComponent.vue";
import { useRouter } from "vue-router"    
import {onMounted,ref} from 'vue'
export default {  
        components: {
            Layout
        },      
        setup(){

            const router=useRouter();
            let path=ref('')
            onMounted(()=>{
                //页面渲染完执行
                 path.value=router.currentRoute.value.fullPath;
                 console.log('app.....',path.value)
            });
            return {
                path
            };
        }
    }
</script>

<style>

</style>
