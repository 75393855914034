import { createRouter, createWebHistory } from 'vue-router'
import Main from '../views/mainPage.vue'
const About=()=>import("../views/aboutPage.vue")
const Case=()=>import("../views/casePage.vue")
const Contact=()=>import("../views/contactPage.vue")
const Product=()=>import("../views/productPage.vue")
const ProductBoard=()=>import("../views/productBoard.vue")
const Solution=()=>import("../views/solutionPage.vue")
const SolutionOne=()=>import("../components/SolutionOneComponent.vue")
const SolutionTwo=()=>import("../components/SolutionTwoComponent.vue")
const SolutionThree=()=>import("../components/SolutionThreeComponent.vue")
const Support=()=>import("../views/supportPage.vue")

const GuideComponent=()=>import("../components/guide/GuideComponent.vue")

const routes = [
  {
    path: '/',
    redirect: '/index',
    component: Main,
  },
  {
    path: '/index',
    component: Main,
  },
  {
    path: '/about',
    component: About,
  },
  {
    path: '/case',
    component: Case,
  },
  {
    path: '/contact',
    component: Contact,
  },
  {
    path: '/product',
    component: Product,
  },
  {
    path: '/board',
    component: ProductBoard,
  },
  {
    path: '/solution',
    component: Solution,
    redirect: '/solution/one',
    children:[//嵌套组件
      {
          path:"one",
          component:SolutionOne
      },
      {
          path:"two",
          component:SolutionTwo
      },
      {
        path:"three",
        component:SolutionThree
      },
    ]
  },
  {
    path: '/support',
    component: Support,
    redirect: '/support/guide',
    children:[//嵌套组件
      {
          path:"guide",
          component:GuideComponent
      },
      {
        path:"loop",
        component:()=>import("../components/guide/LoopPicComponent.vue")
      },
      {
        path:"web",
        component:()=>import("../components/guide/WebPageComponent.vue")
      },
      {
        path:"connection",
        component:()=>import("../components/guide/ConnectionComponent.vue")
      },
      {
        path:"fixedip",
        component:()=>import("../components/guide/FixedIPComponent.vue")
      },
      {
        path:"baseconfig/:id",
        component:()=>import("../components/guide/BaseConfigComponent.vue")
      },
      {
        path:"api/addr",
        component:()=>import("../components/guide/ApiGuideComponent.vue")
      },
      {
        path:"api/equinfo",
        component:()=>import("../components/guide/ApiEquInfoComponent.vue")
      },
      {
        path:"api/equheart",
        component:()=>import("../components/guide/ApiEquHeartComponent.vue")
      },
      {
        path:"api/equreport",
        component:()=>import("../components/guide/ApiEquReportComponent.vue")
      },
      {
        path:"api/equconfig",
        component:()=>import("../components/guide/ApiEquConfigComponent.vue")
      },
      {
        path:"api/equupdate",
        component:()=>import("../components/guide/ApiEquUpdateComponent.vue")
      },
      {
        path:"err/01",
        component:()=>import("../components/guide/Error01Component.vue")
      },
      {
        path:"err/02",
        component:()=>import("../components/guide/Error02Component.vue")
      },
      {
        path:"err/03",
        component:()=>import("../components/guide/Error03Component.vue")
      },
      {
        path:"err/04",
        component:()=>import("../components/guide/Error04Component.vue")
      },
      {
        path:"err/05",
        component:()=>import("../components/guide/Error05Component.vue")
      },
      {
        path:"err/06",
        component:()=>import("../components/guide/Error06Component.vue")
      },
      {
        path:"err/07",
        component:()=>import("../components/guide/Error07Component.vue")
      },
      {
        path:"err/08",
        component:()=>import("../components/guide/Error08Component.vue")
      },
      {
        path:"err/09",
        component:()=>import("../components/guide/Error09Component.vue")
      },
      {
        path:"err/10",
        component:()=>import("../components/guide/Error10Component.vue")
      },
      {
        path:"log",
        component:()=>import("../components/guide/ScanLogComponent.vue")
      },
      {
        path:"cmd",
        component:()=>import("../components/guide/CmdComponent.vue")
      },
    ]
  },
]

const router = createRouter({
  history:createWebHistory(),
  routes
})

//全局守卫
router.beforeEach((to,from,next)=>{
  //to 到哪个页面去
  //from 从哪个页面来
  //最后执行next()页面才会跳转
  next()
})

export default router
